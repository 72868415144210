* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url('fonts/Gilroy-Regular.ttf') format("truetype"); /* все современные браузеры */
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url('fonts/Gilroy-Medium.ttf') format("truetype"); /* все современные браузеры */
}